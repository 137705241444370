import React, { useMemo, useState } from 'react'
import getConfig from 'next/config'
import Router from 'next/router'
import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'
import ArrowRight from '@/assets/img/icons/Arrow_Right.svg'
import Google from '@/assets/img/logo/GOOGLE_COL.svg'
import Line from '@/assets/img/icons/sns/LINE_Clr.svg'
import { fetchFanmeData, useFanmeBackendGet, useUser } from '@/libs/fanme_backend'
import AlertDialog, { AlertState } from '@/components/molecules/Alert/alert'
import FanmeDialog from '@/components/molecules/Alert'
import FullModal from '@/components/atoms/Dialog/FullModal'
import FullModalHeader from '@/components/molecules/Header/FullModalHeader'
import MuiButtonWithoutTheme from '@/components/atoms/MuiButtonWithoutTheme'

const StyledMainContainer = styled.div`
  padding: 16px;
`

const StyledMenuItem = styled.div`
  min-height: 56px;
  border-bottom: solid 1px ${Colors.VERY_LIGHT_GRAY};
  display: flex;
  flex-direction: column;
  position: relative;
  row-gap: 8px;
`

const StyledMenuSingleItem = styled.div`
  height: 72px;
  border-bottom: solid 1px ${Colors.VERY_LIGHT_GRAY};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledMenuTitle = styled.div`
  font-size: 11px;
  font-weight: 500;
  line-height: 11px;
  color: ${Colors.PRIMARY_GRAY};
  min-height: 16px;
  display: flex;
  align-items: center;
`

const StyledMenuValue = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: ${Colors.PRIMARY_GRAY};
  min-height: 24px;
  display: flex;
  align-items: center;
`

interface Props {
  visible: boolean
  onClose: () => void
}

interface SNSConnectionProps {
  name: string
  icon: React.ComponentType<{ width: number; height: number }>
  isConnected: boolean
  url: string
}

type InfoState = {
  message: string
  close: (v: InfoState) => void
  visible: boolean
  checkIconOn: boolean
  exclamationIconOn: boolean
}

interface InfoConfig {
  message: string
  isSuccessful: boolean
}

const INFO_CONFIGS: Record<string, InfoConfig> = {
  success: {
    message: 'アカウント連携を解除しました',
    isSuccessful: true,
  },
  error: {
    message: 'アカウント連携を解除できませんでした',
    isSuccessful: false,
  },
} as const

const SNS_CONFIGS = [
  {
    name: 'Google',
    icon: Google,
  },
  {
    name: 'LINE',
    icon: Line,
  },
]

const { publicRuntimeConfig } = getConfig()

const AuthMenuPopup = ({ visible, onClose }: Props) => {
  const { data: loginData, mutate } = useFanmeBackendGet<any>(`/auth/oauth2/user/info`, {
    auth: true,
  })
  const { data: profile } = useUser()
  const [alertState, setAlertState] = useState({} as AlertState)
  const [infoState, setInfoState] = useState({} as InfoState)

  const createDisconnectAlert = (sns: SNSConnectionProps) => ({
    message: `${sns.name}アカウントとの連携を解除しますか？`,
    actionMessage: '解除する',
    action: () => onConfirmDisconnect(sns),
    close: setAlertState,
    visible: true,
  })

  const changeInfo = (type: keyof typeof INFO_CONFIGS): InfoState => {
    const config = INFO_CONFIGS[type]

    return {
      message: config.message,
      close: () => setInfoState({} as InfoState),
      visible: true,
      checkIconOn: config.isSuccessful,
      exclamationIconOn: !config.isSuccessful,
    }
  }

  const snsConnections = useMemo(() => {
    if (!loginData) return []

    return SNS_CONFIGS.map(config => {
      const providerName = config.name.toLowerCase()
      const isConnected = loginData.providers?.includes(providerName)

      return {
        ...config,
        url: isConnected ? '/auth/oauth2/user/disconnect' : `/connect/${providerName}`,
        isConnected,
      }
    })
  }, [loginData])

  const onConfirmDisconnect = async (sns: SNSConnectionProps) => {
    try {
      await mutate(async () => {
        // 1. API呼び出し
        await fetchFanmeData(`/auth/oauth2/user/disconnect/${sns.name.toLowerCase()}`, {
          method: 'delete',
        })

        // 2. 現在のデータを基に新しいデータを返す
        return {
          ...loginData,
          providers: loginData.providers.filter(
            (provider: string) => provider !== sns.name.toLowerCase(),
          ),
        }
      })
      setInfoState(changeInfo('success'))
    } catch (error) {
      console.error('Failed to disconnect:', error)
      // エラー処理（必要に応じてトースト表示など）
      setInfoState(changeInfo('error'))
    } finally {
      setAlertState({} as AlertState)
    }
  }

  const handleConnectionClick = async (sns: (typeof snsConnections)[number]) => {
    if (sns.isConnected) {
      setAlertState(createDisconnectAlert(sns))
    } else {
      // 連携リクエストのURLにリダイレクト
      await Router.push(`${publicRuntimeConfig.AUTH_DOMAIN}${sns.url}`)
    }
  }

  const onModifyEmail = () => {
    const redirectUrl = `${publicRuntimeConfig.FRONT_URL}/@${profile?.account_identity}?update=email`
    const encoded = encodeURIComponent(redirectUrl)
    Router.push(`${publicRuntimeConfig.AUTH_DOMAIN}/user/email/change?redirect_uri=${encoded}`)
  }

  const renderHeader = () => (
    <>
      <FullModalHeader
        title="アカウント連携"
        onBack={() => {
          onClose()
        }}
      />
    </>
  )
  const renderContent = () => (
    <>
      <StyledMainContainer>
        <StyledMenuItem onClick={onModifyEmail}>
          <StyledMenuTitle>メールアドレス</StyledMenuTitle>
          <StyledMenuValue>
            {loginData && !loginData.external_auth
              ? loginData.email
              : loginData && !loginData.email
              ? '未設定'
              : loginData && loginData.email}
          </StyledMenuValue>
          <ArrowRight
            width={16}
            height={16}
            style={{ position: 'absolute', right: 0, top: 0, bottom: 0, margin: 'auto' }}
          />
        </StyledMenuItem>
        {snsConnections.map(sns => (
          <StyledMenuSingleItem key={sns.name}>
            <div className="flex items-center gap-x-2">
              <sns.icon width={24} height={24} />
              {sns.name}アカウント
            </div>
            <MuiButtonWithoutTheme
              text={sns.isConnected ? '連携解除' : '連携'}
              width={64}
              colored={!sns.isConnected}
              style={{
                fontSize: '12px',
                color: Colors.PRIMARY_GRAY,
                ...(sns.isConnected ? { border: `1px solid ${Colors.PRIMARY_GRAY}` } : {}),
                ...(!loginData.email && loginData.providers.length <= 1 && sns.isConnected
                  ? { border: 'none' }
                  : {}),
              }}
              onClick={() => handleConnectionClick(sns)}
              disabled={!loginData.email && loginData.providers.length <= 1 && sns.isConnected}
            />
          </StyledMenuSingleItem>
        ))}
      </StyledMainContainer>
    </>
  )

  if (!visible || !loginData || !profile) return null
  return (
    <>
      <FullModal
        header={renderHeader()}
        content={renderContent()}
        visible={visible}
        zIndex={10000}
        bodyPadding="0"
      />
      <AlertDialog
        visible={alertState.visible}
        message={alertState.message}
        actionMessage={alertState.actionMessage}
        action={alertState.action}
        close={alertState.close}
      />
      <FanmeDialog
        text={infoState.message}
        visible={infoState.visible}
        onClose={() => infoState.close({} as InfoState)}
        closeOn
        checkIconOn={infoState.checkIconOn}
        exclamationIconOn={infoState.exclamationIconOn}
      />
    </>
  )
}

export default AuthMenuPopup
