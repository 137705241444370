import React, { FC, useCallback, useState } from 'react'
import styled from '@emotion/styled'
import { Button, Text } from '@nextui-org/react'
import { Colors } from '@/constants/styles/color'
import LoadingIcon from '@/components/atoms/LoadingIcon'
import FullModal from '@/components/atoms/Dialog/FullModal'
import FullModalHeader from '@/components/molecules/Header/FullModalHeader'
import BlogArticleBadge from '@/components/molecules/BlogArticleBadge'
import {
  StyledBadgeInnerContainer,
  StyledBadgeOuterContainer,
} from '@/components/molecules/BlogArticleBadge/index.style'
import { useFanmeBackendGet } from '@/libs/fanme_backend'
import BlogCard from '../BlogCard'

const StyledHeaderWrapperWhite = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  height: 72px;
  background-color: ${Colors.WHITE};
  margin-bottom: 16px;
`

const StyledTabButton = styled(Button)<{ status: string }>`
  background-color: ${props =>
    props.status === 'true' ? Colors.PRIMARY_GRAY : Colors.ULTRA_LIGHT_GRAY};
  color: ${props => (props.status === 'true' ? Colors.WHITE : Colors.BLACK)};
  min-width: 56px;
  max-width: 56px;
  height: 40px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
`

interface Props {
  onClose: () => void
}

const BlogArticles = ({ onClose }: Props) => {
  const buttons = ['すべて', 'お知らせ', '重要', 'イベント', 'その他']

  const sortPattern = {
    PRIORITY: 'priority',
    PUBLISHED_AT: 'published_at',
  }

  const [label, setLabel] = useState(0)
  const [sort1] = useState(sortPattern.PUBLISHED_AT)
  const [sort2] = useState(sortPattern.PRIORITY)
  const { data: articlesData, mutate: mutateArticles } = useFanmeBackendGet<any>(
    label > 0
      ? `/fanme/api/creators/self/articles?label=${label - 1}&sort1=${sort1}&sort2=${sort2}`
      : '/fanme/api/creators/self/articles',
    { auth: true },
  )

  const articles = articlesData?.articles

  const { data: badgeData, mutate: mutateBadge } = useFanmeBackendGet<any>(
    '/fanme/api/creators/self/article/badge',
    { auth: true },
  )

  const onCloseModal = () => {
    onClose()
  }

  const handleClick = useCallback(
    (label: number) => {
      setLabel(label)
    },
    [label],
  )

  const renderHeader = () => <FullModalHeader title="お知らせ" onBack={onCloseModal} />
  const ContentHeader: FC = () => (
    <StyledHeaderWrapperWhite>
      {buttons.map((button, index) => {
        const hasUnread =
          index === 0
            ? badgeData?.count > 0
            : badgeData?.labels?.filter(
                (label: { label: number; count: number }) => label.label === index - 1,
              )[0]?.count > 0
        return (
          <StyledBadgeOuterContainer key={`button-${index}`}>
            <StyledTabButton
              key={`button-${index}`}
              status={(label === index).toString()}
              onClick={handleClick.bind(null, index)}
            >
              <StyledBadgeInnerContainer right={3} top={4}>
                {hasUnread && <BlogArticleBadge />}
              </StyledBadgeInnerContainer>
              {button}
            </StyledTabButton>
          </StyledBadgeOuterContainer>
        )
      })}
    </StyledHeaderWrapperWhite>
  )
  const renderContent = () => (
    <>
      <ContentHeader />
      {!articles && <LoadingIcon />}
      {articles?.length === 0 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text
            css={{
              position: 'absolute',
              color: Colors.GRAY,
              fs: '15px',
              lh: '14px',
              fontFamily: 'Noto Sans JP',
              fontStyle: 'normal',
              fontWeight: '500',
              d: 'flex',
              ai: 'center',
              ta: 'center',
              top: '50vh',
            }}
          >
            お知らせはまだありません
          </Text>
        </div>
      ) : (
        <>
          {articles &&
            articles.map((item: any, index: number) => {
              const jst_published_at = new Date(item.published_at).toISOString()
              return (
                <BlogCard
                  key={index}
                  id={item.id}
                  visible={false}
                  title={item.title}
                  description={item.description}
                  image_url={item.image_url}
                  label={item.label}
                  priority={item.priority}
                  published_at={`${jst_published_at.slice(0, 4)}/${jst_published_at.slice(
                    5,
                    7,
                  )}/${jst_published_at.slice(8, 10)}`}
                  published_at_hh_mm={`${jst_published_at.slice(11, 13)}:${jst_published_at.slice(
                    14,
                    16,
                  )}`}
                  content={item.content}
                  readed={item.readed}
                  onClose={() => {
                    console.log('onClose')
                    mutateBadge()
                    mutateArticles()
                  }}
                />
              )
            })}
        </>
      )}
    </>
  )
  return (
    <FullModal
      header={renderHeader()}
      content={renderContent()}
      visible={true}
      zIndex={10000}
      bodyPadding="0"
      background={Colors.ULTRA_LIGHT_GRAY}
    />
  )
}
export default BlogArticles
