import React, { useState } from 'react'
import FanmeLoading from '@/components/atoms/FanmeLoading'
import { Colors } from '@/constants/styles/color'
import FullModal from '@/components/atoms/Dialog/FullModal'
import FullModalHeader from '@/components/molecules/Header/FullModalHeader'
import { useFanmeBackendPost } from '@/libs/fanme_backend'

import {
  StyledBlogBottom,
  StyledBlogContent,
  StyledBlogDate,
  StyledBlogImg,
  StyledBlogLabel,
  StyledBlogTitle,
  StyledWrapper,
  StyledWrapperLabel,
} from './index.style'

interface Props {
  id: number
  visible: boolean
  onClose: () => void
  title: string
  content: string
  image_url: string
  label: number
  priority: number
  published_at: string
  published_at_hh_mm: string
}

const BlogArticleDetail = ({
  id,
  visible,
  onClose,
  title,
  content,
  image_url,
  label,
  published_at,
  published_at_hh_mm,
}: Props) => {
  const [loaded, setLoaded] = useState(false)
  const onCloseModal = () => {
    onClose()
  }

  const labelMap: Record<number, { info: string; color: string }> = {
    0: { info: ' お知らせ ', color: Colors.ORANGE },
    1: { info: ' 重要 ', color: Colors.RED },
    2: { info: ' イベント ', color: Colors.LIGHT_BLUE },
    3: { info: ' その他 ', color: Colors.DARK_GRAY },
  }

  const getLabelData = (label: number) => ({
    info: labelMap[label]?.info || ' お知らせ ',
    color: labelMap[label]?.color || Colors.ORANGE,
  })

  const { info, color } = getLabelData(label)

  function parseContentWithLinks(content: string) {
    const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^)]+)\)/g
    const parts = []
    let lastIndex = 0
    let match
    while ((match = linkRegex.exec(content)) !== null) {
      if (match.index > lastIndex) {
        parts.push(content.slice(lastIndex, match.index))
      }
      parts.push(
        <a
          key={match[2]}
          href={match[2]}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'blue', textDecoration: 'underline' }}
        >
          {match[1]}
        </a>,
      )
      lastIndex = linkRegex.lastIndex
    }
    if (lastIndex < content.length) {
      parts.push(content.slice(lastIndex))
    }
    return parts
  }
  const renderHeader = () => <FullModalHeader title="お知らせ詳細" onBack={onCloseModal} />
  const renderContent = () => (
    <>
      <StyledWrapper>
        <StyledBlogTitle>{title}</StyledBlogTitle>
        <StyledWrapperLabel>
          <StyledBlogDate>{`${published_at} ${published_at_hh_mm}`}</StyledBlogDate>
          <StyledBlogLabel color={color}>{info}</StyledBlogLabel>
        </StyledWrapperLabel>
      </StyledWrapper>
      <StyledBlogImg src={image_url} alt="" />
      <StyledBlogContent>
        {parseContentWithLinks(content.split('\\n').join('\n'))}
      </StyledBlogContent>
      <StyledBlogBottom>FANME運営</StyledBlogBottom>
    </>
  )

  // 既読をつける
  useFanmeBackendPost<any>(`/fanme/api/creators/self/article/${id}/view`, { auth: true })

  return (
    <>
      {loaded && <FanmeLoading />}
      <FullModal
        header={renderHeader()}
        content={renderContent()}
        visible={visible}
        zIndex={10000}
        bodyPadding="0"
        background={Colors.VERY_LIGHT_GRAY}
      />
    </>
  )
}
export default BlogArticleDetail
