import React, { useEffect, useState } from 'react'
import getConfig from 'next/config'
import { MiniApp } from '@torihada-inc/miniapps'
import numeral from 'numeral'
import moment from 'moment'
import useSWR from 'swr'
import { TRANSFER_COMMISSION } from '@/constants'
import { Colors } from '@/constants/styles/color'
import FullModal from '@/components/atoms/Dialog/FullModal'
import Button from '@/components/atoms/Button'
import FullModalHeader from '@/components/molecules/Header/FullModalHeader'
import { useAuth } from '@/contexts/AuthContext'
import { getMiniAppInfo } from '@/pages/api/miniapp'
import {
  userVerificationStatusDenied,
  userVerificationStatusDone,
  userVerificationStatusNone,
  userVerificationStatusPending,
  userVerificationStatusSubmitted,
  useUserVerificationStat,
} from '@/libs/api/user/verification'
import FanmeLoading from '@/components/atoms/FanmeLoading'
import { FmeSpace } from '@/fanmeUi/shared/base'
import { FmeButton } from '@/fanmeUi/elements/button'
import { FmeBox, FmeBoxInner } from '@/fanmeUi/elements/box'
import { FmeMessage } from '@/fanmeUi/elements/message'
import { FmePaddingContainer } from '@/fanmeUi/layouts/container'
import ErrorDialog, { ErrorState } from '@/components/molecules/Alert/error'
import InfoDialog, { InfoState } from '@/components/molecules/Alert/info'
import { getSellerDepositAccount } from '@/pages/api/payment'
import SafariIcon from '@/assets/img/icons/browser/Safari.svg'
import ChromeIcon from '@/assets/img/icons/browser/Chrome.svg'
import QuestionMark from '@/assets/img/icons/Question_Mark.svg'
import { fetchFanmeData } from '@/libs/fanme_backend'
import MoneyManagementReceipt from '../MoneyManagementReceipt'
import MoneyManagementHistory from '../MoneyManagementHistory'
import ActionDialog from '../../Alert/action'
import {
  MoneyIdentification,
  MoneyIdentificationMsg,
  MoneyIdentificationMsgSub,
  MoneyIdentificationMsgSubmittedSub,
  MoneyManageCardBorder,
  MoneyManageCardPrice,
  MoneyManageCardTitle,
  MoneyManageCardTitleContainer,
  MoneyManageDescription,
  MoneyManageDescriptionRight,
  MoneyManageNotice,
  NotProvisionalSalesData,
  ProvisionalHelperContainer,
  ProvisionalHelperText,
  ProvisionalSalesResultContainer,
  ProvisionalSalesText,
  SalesMoney,
  SalesMoneyText,
  TransferFeeText,
} from './index.style'

interface Props {
  accountId: string
  visible: boolean
  onClose: () => void
}

const { publicRuntimeConfig } = getConfig()

const MoneyManagementList = ({ accountId, visible, onClose }: Props) => {
  const [visibleSalesBalanceHistory, setVisibleSalesBalanceHistory] = useState(false)
  const { token } = useAuth()
  const [miniApp, setMiniApp] = useState<MiniApp | undefined>()
  const [depositAccount, setDepositAccount] = useState<SellerDepositAccountResponse>()
  const [visibleReceipt, setVisibleReceipt] = useState(false)
  const [visibleActionDialog, setVisibleActionDialog] = useState(false)
  const [errorState, setErrorState] = useState({} as ErrorState)
  const [infoState, setInfoState] = useState({} as InfoState)
  const { data: userVerificationStatusData } = useUserVerificationStat()
  const userVerificationStatus = userVerificationStatusData?.status
  const userVerificationErrMsg = (() => {
    switch (userVerificationStatus) {
      case userVerificationStatusPending:
        return ''
      default:
        if (
          userVerificationStatusData?.metadata === undefined ||
          userVerificationStatusData?.metadata === '{}'
        ) {
          return ''
        } else {
          try {
            const parsedMetadata = JSON.parse(userVerificationStatusData.metadata)
            return parsedMetadata.error.error_message
          } catch (e) {
            return ''
          }
        }
    }
  })()

  const invalidUrlError = {
    message: '出金のリクエストに失敗しました',
    visible: true,
    close: setErrorState,
  }

  const buttonPublicationInfo = {
    message: '出金のリクエストに成功しました',
    visible: true,
    close: setInfoState,
  }

  type SellerDepositAccountResponse = {
    pending_list?: Array<AppliedData>
    not_applied_list?: Array<NotAppliedData>
    applied_detail_list?: Array<AppliedDetailList>
    balance_amount?: number
    current_month?: number
    transfer_state?: boolean
  }

  type AppliedDetailList = {
    applied_list?: Array<AppliedData>
    fee?: number
  }

  type AppliedData = {
    year_month?: string
    amount?: number
  }

  type NotAppliedData = {
    year_month?: string
    amount?: number
    latest_expire_date?: string
  }

  const initMiniApp = async () => {
    const res: any = await getMiniAppInfo(accountId)

    const miniAppData = new MiniApp(publicRuntimeConfig.PAYMENT_URL, res.user_uid, token as string)
    setMiniApp(miniAppData)
  }

  const getDepositAccount = async () => {
    try {
      const res = await getSellerDepositAccount()
      setDepositAccount(res)
    } catch (error) {
      console.error('failed GMO account information', error)
    }
  }

  const handleEkyc = async () => {
    window.open('/ekyc', '_blank')
  }

  useEffect(() => {
    getSellerDepositAccount().then((res: SellerDepositAccountResponse) => {
      setDepositAccount(res)
    })
  }, [miniApp])

  useEffect(() => {
    if (token && visible) {
      initMiniApp()
    }
  }, [accountId, token, visible])

  const month = (date: string | undefined) => {
    if (date?.charAt(4) == '0') {
      return date?.slice(-1)
    } else {
      return date?.slice(-2)
    }
  }

  const { data: accountStatus } = useSWR(
    accountId ? `/fanme/api/restricted_accounts/@${accountId}` : null,
    fetchFanmeData,
  )

  const renderUserVerificationStatus = (item?: AppliedData[], state?: boolean) => {
    if (userVerificationStatus === undefined) {
      return <FanmeLoading />
    }
    const total = item?.reduce((sum, item) => sum + (item.amount || 0), 0) || 0
    switch (userVerificationStatus) {
      case userVerificationStatusDone:
        if (item && item.length > 0) {
          return (
            <ProvisionalSalesResultContainer>
              <FmeSpace size={16 / 12} />
              <ProvisionalHelperContainer isLeft={true}>
                <a
                  href={
                    'https://fanme-support.zendesk.com/hc/ja/articles/6999443352207-%E5%A3%B2%E4%B8%8A%E9%87%91%E3%82%92%E6%8C%AF%E3%82%8A%E8%BE%BC%E3%82%80%E3%81%AB%E3%81%AF%E3%81%A9%E3%81%86%E3%81%97%E3%81%9F%E3%82%89%E3%82%88%E3%81%84%E3%81%A7%E3%81%99%E3%81%8B-FANME-%E3%83%95%E3%82%A1%E3%83%B3%E3%83%9F%E3%83%BC'
                  }
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <ProvisionalHelperText>
                    <QuestionMark className={`inline-block -ml-0.5`} /> 売上金を受け取るには？
                  </ProvisionalHelperText>
                </a>
              </ProvisionalHelperContainer>
              <FmeButton
                // 本人確認済みの場合は以下のsetVisibleReceiptを使用する
                onClick={async () => {
                  if (accountStatus?.isRestricted) {
                    return
                  }
                  setVisibleReceipt(true)
                }}
                disabled={total <= TRANSFER_COMMISSION || !state || accountStatus?.isRestricted}
              >
                {state ? '受取申請' : '申請中のためしばらくお待ちください'}
              </FmeButton>
            </ProvisionalSalesResultContainer>
          )
        } else {
          return null
        }
      case userVerificationStatusPending:
        return (
          <ProvisionalSalesResultContainer>
            <ProvisionalSalesText>書類提出待ち</ProvisionalSalesText>
            <FmeButton onClick={handleEkyc}>書類提出へ</FmeButton>
          </ProvisionalSalesResultContainer>
        )
    }
    return (
      <ProvisionalSalesResultContainer>
        <ProvisionalSalesText>
          売上を受け取るには<MoneyIdentification>本人確認</MoneyIdentification>が必要です
        </ProvisionalSalesText>
        <ProvisionalHelperContainer isLeft={false}>
          <a
            href={
              'https://fanme-support.zendesk.com/hc/ja/articles/6998452407439-%E6%9C%AC%E4%BA%BA%E7%A2%BA%E8%AA%8D%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6-FANME-%E3%83%95%E3%82%A1%E3%83%B3%E3%83%9F%E3%83%BC'
            }
            target={'_blank'}
            rel="noreferrer"
          >
            <ProvisionalHelperText>
              <QuestionMark className={`inline-block -ml-0.5`} /> 本人確認とは？
            </ProvisionalHelperText>
          </a>
          <a
            href={
              'https://fanme-support.zendesk.com/hc/ja/articles/6999443352207-%E5%A3%B2%E4%B8%8A%E9%87%91%E3%82%92%E6%8C%AF%E3%82%8A%E8%BE%BC%E3%82%80%E3%81%AB%E3%81%AF%E3%81%A9%E3%81%86%E3%81%97%E3%81%9F%E3%82%89%E3%82%88%E3%81%84%E3%81%A7%E3%81%99%E3%81%8B-FANME-%E3%83%95%E3%82%A1%E3%83%B3%E3%83%9F%E3%83%BC'
            }
            target={'_blank'}
            rel="noreferrer"
          >
            <ProvisionalHelperText>
              <QuestionMark className={`inline-block -ml-0.5`} /> 売上金を受け取るには？
            </ProvisionalHelperText>
          </a>
        </ProvisionalHelperContainer>
        {userVerificationStatus === userVerificationStatusDenied && (
          <>
            <FmeSpace size={8 / 12} />
            <FmeMessage type={`danger`} outline={true}>
              <MoneyIdentificationMsg color={Colors.ERROR}>
                本人確認が承認されませんでした
              </MoneyIdentificationMsg>
              <br />
              {userVerificationErrMsg.length > 0 && (
                <MoneyIdentificationMsgSub color={Colors.ERROR}>
                  ＜{userVerificationErrMsg}＞
                </MoneyIdentificationMsgSub>
              )}
            </FmeMessage>
            <FmeSpace size={16 / 12} />
          </>
        )}

        {userVerificationStatus === userVerificationStatusSubmitted ? (
          <>
            <FmeSpace size={8 / 12} />
            <FmeMessage type={`info`}>
              <MoneyIdentificationMsg color={Colors.WHITE}>本人確認中</MoneyIdentificationMsg>
              <br />
              <MoneyIdentificationMsgSubmittedSub color={Colors.WHITE}>
                申請が受理されるまでお待ちください
              </MoneyIdentificationMsgSubmittedSub>
            </FmeMessage>
          </>
        ) : (
          <>
            <FmeSpace size={8 / 12} />
            <FmeButton onClick={async () => setVisibleActionDialog(true)}>
              本人確認{userVerificationStatus === userVerificationStatusDenied ? 'を再度' : ''}申請
            </FmeButton>
            {visibleActionDialog && (
              <ActionDialog
                message={
                  <div className={`font-sansJP font-medium text-sm`}>
                    本人確認の申請は、
                    <span className={`font-sansJP font-medium text-sm text-RED_ORANGE`}>
                      普段お使いのブラウザ
                    </span>
                    <br />（{' '}
                    <span className={`inline-block`}>
                      <SafariIcon className={`relative -bottom-[3px] `} />
                    </span>{' '}
                    Safari、
                    <span className={`inline-block`}>
                      <ChromeIcon className={`relative -bottom-[3px] `} />
                    </span>{' '}
                    Chrome等 ）でのご利用を
                    <br />
                    推奨しています。
                  </div>
                }
                actionMessage="本人確認に進む"
                action={handleEkyc}
                close={() => setVisibleActionDialog(false)}
                visible={visibleActionDialog}
                checkIconOn={false}
              />
            )}
          </>
        )}
      </ProvisionalSalesResultContainer>
    )
  }

  const renderHeader = () => <FullModalHeader title="売上金管理" onBack={onClose} />

  const renderContent = () => (
    <FmePaddingContainer left={24} right={24} top={24} bottom={24}>
      <FmeBox shadow={'none'}>
        <FmeBoxInner>
          <SalesMoneyText>総売上</SalesMoneyText>
          <SalesMoney visible={depositAccount?.balance_amount ? true : false}>
            ￥
            {depositAccount?.balance_amount
              ? numeral(depositAccount?.balance_amount).format('0,0')
              : 0}
          </SalesMoney>
          <Button
            disable={depositAccount?.balance_amount ? false : true}
            text={'売上金明細'}
            width={'100%'}
            height={'48px'}
            fontFamily={'Noto Sans JP'}
            fontWeight={'700'}
            fontSize={'14px'}
            lineHeight={'14px'}
            borderRadius={'24px'}
            bgColor={depositAccount?.balance_amount ? Colors.PRIMARY_GRAY : Colors.PALE_GRAY}
            textColor={Colors.WHITE}
            onClick={() => depositAccount?.balance_amount && setVisibleSalesBalanceHistory(true)}
          />
        </FmeBoxInner>
      </FmeBox>

      <FmeSpace size={2} />
      <MoneyManageDescription>暫定売上</MoneyManageDescription>

      <FmeBox shadow={'none'}>
        {depositAccount?.pending_list ? (
          <>
            {depositAccount?.pending_list?.map((it, i: number) => {
              return (
                <MoneyManageCardTitleContainer key={`deposit-account-container-${i + 1}`} line={i}>
                  <MoneyManageCardTitle>{month(it.year_month)}月分</MoneyManageCardTitle>
                  <MoneyManageCardPrice>￥{numeral(it.amount).format('0,0')}</MoneyManageCardPrice>
                </MoneyManageCardTitleContainer>
              )
            })}
          </>
        ) : (
          <NotProvisionalSalesData>売上情報がありません</NotProvisionalSalesData>
        )}
      </FmeBox>

      <FmeSpace size={2} />
      <MoneyManageDescription>確定売上</MoneyManageDescription>
      <FmeBox shadow={'none'}>
        {(depositAccount?.not_applied_list?.length || 0) > 0 ? (
          depositAccount?.not_applied_list?.map((it, i: number) => {
            return (
              <>
                <MoneyManageCardBorder key={`deposit-account-${i + 1}`}>
                  <MoneyManageCardTitleContainer line={i}>
                    <MoneyManageCardTitle>{month(it.year_month)}月分</MoneyManageCardTitle>
                    <MoneyManageCardPrice>
                      ￥{numeral(it.amount).format('0,0')}
                    </MoneyManageCardPrice>
                  </MoneyManageCardTitleContainer>
                </MoneyManageCardBorder>
                <MoneyManageDescriptionRight>
                  直近の受取申請期限
                  <span>{moment(it.latest_expire_date)?.format('YYYY/MM/DD')}</span>
                </MoneyManageDescriptionRight>
              </>
            )
          })
        ) : (
          <NotProvisionalSalesData>売上情報がありません</NotProvisionalSalesData>
        )}
        {renderUserVerificationStatus(
          depositAccount?.not_applied_list,
          depositAccount?.transfer_state,
        )}
      </FmeBox>

      <FmeSpace size={2} />
      {depositAccount?.applied_detail_list && (
        <>
          <MoneyManageDescription>受取申請済み</MoneyManageDescription>
          <FmeBox shadow={'none'}>
            {depositAccount?.applied_detail_list?.map((item, i: number) => {
              return item.applied_list?.map((it, r: number) => {
                return (
                  <MoneyManageCardTitleContainer key={`deposit-account-${i + 1}-${r + 1}`} line={r}>
                    <MoneyManageCardTitle>{month(it.year_month)}月分</MoneyManageCardTitle>
                    <MoneyManageCardPrice>
                      ¥{numeral(it.amount).format('0,0')}
                      <TransferFeeText>
                        振込手数料<span>{`-¥${numeral(item.fee).format('0,0')}`}</span>
                      </TransferFeeText>
                    </MoneyManageCardPrice>
                  </MoneyManageCardTitleContainer>
                )
              })
            })}
          </FmeBox>
        </>
      )}

      <MoneyManageNotice>
        *受取申請は、確定売上金残高が441円以上から可能です。
        <br />
        *受取申請期限を過ぎると、自動的に過去の振込口座への支払い処理が行われます。振込が正常に行えなかった場合、売上金は失効します。
        <br />
        *受取申請は、一度に上限100万円まで行えます。100万円を超える金額を送金したい場合は少し時間をおいて再度送金してください。
        <br />
        *売上金は毎日0時に反映されます。
      </MoneyManageNotice>
    </FmePaddingContainer>
  )

  return (
    <>
      <FullModal
        background={Colors.ULTRA_LIGHT_GRAY}
        header={renderHeader()}
        content={renderContent()}
        visible={visible}
        zIndex={10000}
        bodyPadding="0"
      />

      <MoneyManagementHistory
        accountId={accountId}
        visibleSalesBalanceHistory={visibleSalesBalanceHistory}
        onClose={() => setVisibleSalesBalanceHistory(false)}
      />

      <MoneyManagementReceipt
        accountId={accountId}
        visible={visibleReceipt}
        month={depositAccount?.current_month}
        onClose={() => setVisibleReceipt(false)}
        errorState={() => setErrorState(invalidUrlError)}
        infoState={() => setInfoState(buttonPublicationInfo)}
        updateState={() => getDepositAccount()}
      />

      <ErrorDialog
        message={errorState.message}
        visible={errorState.visible}
        close={setErrorState}
      />
      <InfoDialog message={infoState.message} visible={infoState.visible} close={setInfoState} />
    </>
  )
}

export default MoneyManagementList
