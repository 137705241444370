import styled from '@emotion/styled'
import { Colors } from '@/constants/styles/color'

const StyledWrapper = styled.div`
  background-color: ${Colors.WHITE};
  width: 100%;
  min-height: 104px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledWrapperLabel = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const StyledBlogTitle = styled.div`
  color: ${Colors.PRIMARY_GRAY};
  font-size: 13px;
  font-weight: 500;
  line-height: 160%;
  text-align: left;
  margin-bottom: 8px;
`

const StyledBlogDate = styled.div`
  color: ${Colors.GRAY};
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
`

const StyledBlogLabel = styled.div<{ color?: string }>`
  color: ${props => props.color || Colors.PRIMARY_GRAY};
  border: ${props =>
    props.color ? `0.5px solid ${props.color}` : `0.5px solid ${Colors.PRIMARY_GRAY}`};
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  border-radius: 8px;
  width: 56px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledBlogImg = styled.img`
  width: calc(100% - 32px);
  max-width: 344px;
  margin: 16px auto 24px;
`

const StyledBlogContent = styled.div`
  width: 100%;
  max-width: 344px;
  height: auto;
  margin: auto;
  font-size: 11px;
  font-weight: 400;
  line-height: 160%;
  text-align: left;
  white-space: pre-wrap;
  a {
    color: blue;
    text-decoration: underline;
  }
`

const StyledBlogBottom = styled.div`
  width: 100%;
  max-width: 344px;
  margin: 16px auto;
  text-align: right;
  font-size: 11px;
  font-weight: 400;
  line-height: 160%;
`

export {
  StyledWrapper,
  StyledWrapperLabel,
  StyledBlogTitle,
  StyledBlogDate,
  StyledBlogLabel,
  StyledBlogImg,
  StyledBlogContent,
  StyledBlogBottom,
}
