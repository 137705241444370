import React, { useState, useEffect } from 'react'
import { useRouter, useSearchParams } from 'next/navigation'
import MoneyManagementIcon from '@/assets/img/icons/MoneyManagement.svg'
import MoneyManagementList from '../MoneyManagementList'
import { StyledMyMenuItem, StyledMyMenuItemName } from '../index.style'

interface Props {
  accountIdentity: string
}

const MoneyManagement = ({ accountIdentity }: Props) => {
  const searchParams = useSearchParams()
  const [visible, setVisible] = useState(!!searchParams?.get('moneyManagement'))
  const { push } = useRouter()

  useEffect(() => {
    const elemViewMain = document.getElementById('viewMain')
    if (elemViewMain) elemViewMain.style.display = visible ? 'none' : 'block'
    const url = new URL(window.location.href)
    if (visible) {
      url.searchParams.set('moneyManagement', '1')
    } else {
      url.searchParams.delete('moneyManagement')
    }
    push(url.toString())
  }, [visible])

  return (
    <>
      <StyledMyMenuItem onClick={() => setVisible(true)} width="144px">
        <MoneyManagementIcon width={24} height={24} />
        <StyledMyMenuItemName>売上金管理</StyledMyMenuItemName>
      </StyledMyMenuItem>

      <MoneyManagementList
        accountId={accountIdentity}
        visible={visible}
        onClose={() => setVisible(false)}
      />
    </>
  )
}

export default MoneyManagement
